<template>
  <div>
    <div v-if="!isLoginState || isMain" class="main_self">
      <router-view></router-view>
    </div>
    <div class="page-main" v-else>
      <Nav :is-menu=" appInfo.appId" :userInfo="userInfo" :shortcutKeyList="shortcutKeyList" :mode="zhuti.mode"
           :menuList="menuList" :defaultMenuGroup="defaultMenuGroup"
           :defaultMenuId="defaultMenuId" ref="navRef"></Nav>
      <!-- 面包屑导航 -->
      <div class="pt-[10px]  p-l-20 p-r-20"
           v-if="zhuti.mode==='horizontal'&&pathname!=='/app'&&pathname!=='/informationList' && breadcrumbData">
        <div class='flex items-center flex-wrap '>
          <span>
            <span class="text-[12px]" v-for="(item,index) in breadcrumbData.split('/')" :key="index">
              <span class="text-[12px]" v-if="index>0"> / </span>
              <span class="text-[12px]"
                    :class="index === breadcrumbData.split('/').length-1?'span-primary':'span-text'">
              {{ item }}
              </span>
            </span>
          </span>
        </div>
      </div>
      <div class='layout flex '
           :style="{height: zhuti.mode === 'horizontal'  ? 'calc(100% - 116px)'  : 'calc(100% - 67px)'}"
      >
        <div
            class='layout-sidebar bg-menu flex flex-col layoutSidebar_style transition-width duration-200  menu-shadow mr-0.5'
            v-if="zhuti.mode==='vertical'&&pathname!=='/app'&&pathname!=='/informationList' && appInfo.appId "
        >
          <div class='layout-sidebar-menubar overflow-hidden h-full'>
            <div class="p20 flex menu-line">
              <el-input
                  v-model="menuValue"
                  @input="menuValueChange"
                  placeholder="检索功能"
                  :prefix-icon="SearchIcon"
                  style="width: 138px"
                  class="searchIcon"
                  v-if="!isCollapse"
              />
              <el-tooltip :content="isCollapse?'展开':'隐藏'" placement="right" effect="light"
                          popper-class="tooltips">
                <el-button style="width: 32px;padding:12px 0;" @click="isCollapseFun" class="ml-1.5 collapseBtn"
                           :icon="!isCollapse?DArrowLeftIcon:DArrowRightIcon"/>
              </el-tooltip>
            </div>
            <el-scrollbar wrap-class='scrollbar-wrapper' style="height:calc(100% - 95px)">
              <sliderMenu :menuList="menuList" :collapse="isCollapse"
                          :defaultMenuGroup="defaultMenuGroup"
                          :menuIndex="menuIndex"
                          :defaultActive="defaultMenuId"
                          :mode="zhuti.mode"></sliderMenu>
            </el-scrollbar>
          </div>
        </div>

        <div class='layout-main'
             :class="zhuti.mode==='horizontal'?'pt-10':'pt-20'"
             :style="{width: zhuti.mode==='vertical'&&pathname!=='/app'&&pathname!=='/informationList' && menuList.length?!isCollapse?'calc(100% - 216px)':'calc(100% - 78px)':'100%'}"
        >
          <div v-show="isShowMack" style="position: fixed;inset: 0;z-index:9999" class="h-full w-full "></div>
          <div id="subapp-viewport" class="overflow-x-hidden"
               v-show="pathname!=='/app'&&pathname!=='/informationList'&& pathname!=='/userInfo'&& pathname!=='/notice'&& pathname!=='/noticeDetails'&& pathname!=='/informationDetails'"></div>
          <router-view></router-view>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import "@/utils/registerMicroAppsConfig"
import {getUserInfoByToken} from "@/api/UserInfo";
import {mapMutations, mapState} from "vuex";
import {Search, DArrowLeft, DArrowRight} from '@element-plus/icons-vue';
import {getAppInfo, getAppQuickFuncListApi, getMenuPrv, getOrgInfoByUser, noticeCount} from "@/api";
import comAbout from '@/views/About.vue'
import sliderMenu from '@/components/sliderMenu.vue'
import {appsData, dynamicRegisterApps} from "@/utils/registerMicroAppsConfig";
import Nav from "@/components/Nav.vue"
import {updateTopic} from "@/utils/common";
import routes from "@/router/index"
import store from "@/store";

export default {

  name: "qiankunMenu",
  components: {
    Nav,
    comAbout,
    sliderMenu,
    Search
  },
  computed: {
    ...mapState([
      "userInfo", "appInfo"
    ]),
    // 定义 SearchIcon 变量为 Search 图标组件
    SearchIcon() {
      return Search;
    }
    , DArrowLeftIcon() {
      return DArrowLeft;
    }, DArrowRightIcon() {
      return DArrowRight;
    }
  },
  props: {
    appName: {
      type: String, default: ''
    },
    senName: {
      type: String, default: ''
    },
    imgUrl: {
      type: String, default: ''
    },
    appNameEnName: {
      type: String, default: ''
    },

  },
  created() {

  },
  // 其他配置...
  watch: {
    $route(to, from) {
      this.isShowMack = true
      setTimeout(() => {
        this.isShowMack = false
      }, 1500)
      let r = routes.getRoutes()
      console.log(r);
      let whiteList = r.map(item => item.path).filter(item => {
        ['/userInfo', '/notice'].includes(item)
      })
      let current = location.pathname;
      if (current === '/app' || location.pathname === '/app') {
        this.isCollapse = true
        document.title = '工作台'
        sessionStorage.removeItem('editableTabs');
        sessionStorage.removeItem('APPINFO');
        this.SET_APPINFO({});
        this.setEditableTabs([]);
        sessionStorage.removeItem('topic')
      }
      this.isMain = ['/401', '/404', '/403'].includes(current)
      console.log(whiteList,current,"1111");
      if (whiteList.includes(current)) {
        sessionStorage.removeItem('topic')
      }
      let topic = JSON.parse(sessionStorage.getItem("topic")) || {}
      let mode = topic.navigationViewType // 1横向  2纵向
      // 自定义现在的主题样式
      this.zhuti = {
        mode: mode === '1' ? 'horizontal' : 'vertical', //vertical , horizontal
      }
      this.pathname = location.pathname
      let token = sessionStorage.getItem('token')
      updateTopic()
      if (token) {
        let tokenState = sessionStorage.getItem('tokenState')
        let beforeQuestionMark = current.split('?')[0];
        // let historyList = sessionStorage.getItem('historyList') ? JSON.parse(sessionStorage.getItem('historyList')) : []
        // const menuList = sessionStorage.getItem('menuList') ? JSON.parse(sessionStorage.getItem('menuList')) : null
        // let updatedArray = this.addObjectIfNotExists(historyList, beforeQuestionMark, menuList)
        // sessionStorage.setItem("historyList", JSON.stringify(updatedArray))
        this.isLoginState = token && tokenState == 3 ? true : false
        const routerList = JSON.parse(sessionStorage.getItem('routerList'))
        this.menuList = routerList ? routerList.filter(item => item.showFlag === '1') : []
        this.getActiveId(this.menuList)
        this.menuListAll = routerList ? routerList : []

        this.menuValue = ''
        // 第一菜单查找面包屑
        if (!['/401', '/404', '/403', '/app'].includes(beforeQuestionMark)) {
          let parentPath = this.findPathInTree(routerList, beforeQuestionMark)
          this.breadcrumbData = parentPath ? parentPath.parentPath : ''
        }
        let menuList = sessionStorage.getItem('menuList') ? JSON.parse(sessionStorage.getItem('menuList')) : null
        this.$nextTick(() => {
          this.$refs.navRef?.initTab()
          if (menuList) {
            document.title = menuList.name
          }
        })


      } else {
        this.isLoginState = false
      }
    },
  },
  data() {
    return {
      isShowMack: false,
      intervalId: null,
      menuIndex: 0,
      userInfo: {},
      shortcutKeyList: [],  // 快捷功能列表
      isLoginState: false, //  是否登录
      isMain: true, //  是否在主应用
      pathname: "",
      breadcrumbData: '', // 面包屑导航
      isCollapse: true,
      menuValue: '',
      zhuti: null,
      defaultMenuId: "",
      defaultMenuGroup: [],
      userAvatar: '',
      logoPhoto: "",
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      tenantList: [],
      appList: [],
      menuList: [],      // 检索出来的菜单功能
      menuListAll: [],   // 全部菜单功能
      isHomeState: true
    }
  },
  methods: {
    ...mapMutations(['setEditableTabs','SET_APPINFO']),
    addObjectIfNotExists(array, beforeQuestionMark, newObj) {
      console.log(array);
      // 检查数组中是否已经存在具有相同path的对象
      const exists = array.some(item => item.path === beforeQuestionMark);
      // 如果不存在，则创建一个新的对象并添加到数组中
      if (!exists) {
        if (newObj) {
          array.push(newObj);
        }
      }
      // 返回更新后的数组
      return array;
    },
    // 查找特定路径的递归函数
    findPathInTree(tree, path) {
      if (!tree) return
      for (const node of tree) {
        // 检查当前路径是否与目标路径匹配
        let p = node.path
        if (p && p.indexOf("?") > -1) {
          p = p.split("?")[0]
        }
        if (p === path) {
          return node; // 找到匹配的节点，返回它
        }
        // 如果节点有子节点，递归查找
        if (node.children) {
          const result = this.findPathInTree(node.children, path);
          if (result) {
            return result; // 如果在子节点中找到匹配的路径，返回结果
          }
        }
      }
      return null; // 如果路径未找到，返回null
    },
// 模糊查询检索菜单树
    filterTree(data, searchTerm) {
      // Helper function to recursively filter tree
      function filterNode(node) {
        // Check if the node's name includes the searchTerm
        const matchesSearch = node.name.includes(searchTerm);

        // Filter children recursively
        const filteredChildren = (node.children || []).map(filterNode).filter(Boolean);

        // Include the node if it matches the search or has matching children
        if (matchesSearch || filteredChildren.length > 0) {
          return {
            ...node,
            children: filteredChildren
          };
        }

        // Otherwise, exclude this node from the filtered result
        return null;
      }

      // Start filtering from the root of the data
      return data.map(filterNode).filter(Boolean);
    }
    ,
// 菜单检索
    menuValueChange(e) {
      this.menuList = this.filterTree(this.menuListAll, this.menuValue)
      this.menuIndex++
      let arr = []
      this.menuList.forEach((item, index) => {
        arr.push(index.toString())
      })
      this.defaultMenuGroup = arr
      // this.defaultActive =  '0,0'
    }
    ,
// 选择app
// isApp(row) {
//   console.log(row)
//   localStorage.setItem('appid', row.appId)
//   localStorage.setItem('appRow', JSON.stringify(row))
//   localStorage.setItem('imgUrl', location.origin + process.env.VUE_APP_URL_IMG + row.path)
//   localStorage.setItem('appName', row.sname)
//   localStorage.setItem('appNameEnName', row.enName)
//
// },
// 获取快捷键
// 获取当前菜单的默认activeId
    getActiveId(data) {
      if (!data) {

        return
      }
      // 获取当前的菜单id
      let pathname = location.pathname
      console.log(this.defaultMenuId)
      this.defaultMenuId = findMenuIndex(data, pathname);

      function findMenuIndex(menuList, targetPath, parentIndex = '') {
        for (let i = 0; i < menuList.length; i++) {
          const menu = menuList[i];
          if (menu.path === targetPath) {
            return parentIndex === '' ? i.toString() : parentIndex + '-' + i;
          } else if (menu.children) {
            const index = findMenuIndex(menu.children, targetPath, parentIndex === '' ? i.toString() : parentIndex + '-' + i);
            if (index) {
              return index;
            }
          }
        }
        return null;
      }


    }
    ,
// 关闭app
    closeApp() {
      this.appList.forEach(item => {
        item.show = false
      })
    }
    ,
    async isSuccess(type = '') {
      await this.getRouters()
      this.tenantList = []
      // 获取用户信息
      const getInfoResult = await this.getInfo();
      if (getInfoResult.code === 200) {
        await this.getOrgInfoByUserApi()
      }
    }
    ,
// 获取路由
    getRouters(activePath) {
      let token = sessionStorage.getItem('token')
      let tokenState = sessionStorage.getItem('tokenState')
      this.isLoginState = token && tokenState == 3 ? true : false
      this.pathname = activePath ? activePath : location.pathname
      let pathname = activePath ? activePath : location.pathname
      this.isRegister = false
      this.isRetrievePass = false
      this.isAddInfoDom = false
      if (pathname === '/register') {
        this.isRegister = true
      }
      if (pathname === '/additionInfo') {
        this.isAddInfoDom = true
      }
      if (pathname === '/retrievePassword') {
        this.isRetrievePass = true
      }
      if (pathname === '/announcementView') {
        this.announcementViewFlag = true
      } else {
        this.announcementViewFlag = false
      }
      //window.addEventListener('storage', this.handleStorageChange);
      // this.defaultMenuId = store.state.menuId
      // this.defaultMenuGroup = store.state.menuGroup
    }
    ,
    /* 调用获取租户列表的list */
    getOrgInfoByUserApi() {
      getOrgInfoByUser().then(res => {
        /*
      *  获取组织信息 -- 如果组织不存在
      *  补充资料从组织下手
      * */
        if (!res.data.length) {
          this.$router.push({path: "/additionInfo", query: {active: 2}})
          return
        }
        if (res.code == 200 && res.data.length) {
          let tenantList = res.data || []
          this.tenantList = tenantList

          this.chooseTenant = sessionStorage.getItem('chooseTenant') ? sessionStorage.getItem('chooseTenant') : this.tenantList[0].orgCode
          this.chooseTenantName = sessionStorage.getItem('chooseTenantName') ? sessionStorage.getItem('chooseTenantName') : this.tenantList[0].orgName
          // document.title = this.chooseTenantName  // 设置页面标题
          // this.senName = this.tenantList[0].senName
          // let orgFullName = localStorage.getItem('orgFullName') ? localStorage.getItem('orgFullName') : this.tenantList[0].orgFullName
          // localStorage.setItem('orgFullName', orgFullName)
          // localStorage.setItem('chooseTenant', this.chooseTenant)
          // localStorage.setItem('chooseTenantName', this.chooseTenantName)
          // let photo = this.tenantList[0].photoTemp ? this.tenantList[0].photoTemp : null
          // this.logoPhoto = localStorage.getItem('logoPhoto') ? localStorage.getItem('logoPhoto') : photo
          // 获取应用列表信息
          this.getAppInfoApi()
          // getlogoinfo().then(res=>{
          //   this.imgUrl=res.data.url
          // })
          // 缓存内添加一个appid
          let appid = sessionStorage.getItem("appid")
          let appRow = JSON.parse(sessionStorage.getItem("appRow"))
          if (appid) {
            this.isMenuState = true
            this.isApp(appRow)
          }
        } else {
          //  企业未认证
          this.tenantList = []
          this.appList = []
        }

      }).catch(err => {
        // 401 不直接调用接口退出   要清除缓存
        this.handleOptins('401')
      })
    }
    ,
// 获取应用列表信息
    getAppInfoApi() {
      getAppInfo(this.chooseTenant).then(res => {
        this.appList = res.data || []
        /*
             *  获取应用信息，如果应用不存在直接添加应用
             * */
        // if (!this.appList.length) {
        //   this.$router.push({path: "/additionInfo", query: {active: 3}})
        //   return
        // }
        /* 新增一个逻辑，如果列表只有一个直接跳进去  */
        // if (this.appList.length === 1) {
        //   this.$nextTick(() => {
        //     this.$refs.comAbourRef.chooseApp(this.appList[0])
        //   })
        // }
      })
    }
    ,
    getInfoListMenu(arr, pathname, parent = null) {
      for (let i = 0; i < arr.length; i++) {
        const path = arr[i].path
        const node = arr[i]
        if (pathname === path) {
          node.parentName = parent.menuName
          return node
        }
        if (typeof node === 'object' && node.children && node.children.length > 0) {
          const foundItem = this.getInfoListMenu(arr[i].children, pathname, arr[i]);
          if (foundItem) {
            return foundItem;
          }
        }
      }
      return null;
    }
    ,
    getInfo() {
      // 根据 根据token获取用户名
      return getUserInfoByToken().then(r => {
        // if (r.code === 200) {
        //   return getUserInfo({username: r.data.userName}).then(res => {
        //     // let imageUrl = res.data.sysUser.photoTemp ? res.data.sysUser.photoTemp : '';
        //     // this.SET_HEADERIMG(imageUrl);
        //     return {code: 200};
        //   });
        // } else {
        //   return {code: r.code};
        // }
      }).catch((err) => {
        // 401 不直接调用接口退出   要清除缓存
        this.handleOptins('401')
        return {code: 500}; // 其他错误
      });
    }
    ,
// getInfo(){
//   //localStorage.getItem('name')
//   getUserInfo({username:localStorage.getItem('name')}).then(res=>{
//     this.userAvatar = res.data.sysUser.photo ?  process.env.VUE_APP_URL_IMG  + res.data.sysUser.photo : ''
//   })
// },
    goHome(text) {

      this.$emit('goHome', text)
    }
    ,
    handleOptins(text) {
      this.$emit('handleOptins', text)
    }
    ,
// 展开隐藏菜单
    isCollapseFun() {
      this.isCollapse = !this.isCollapse
      document.querySelectorAll('.tooltips').forEach(element => {
        element.style.display = 'none'
      });

    }
    ,
    navigatorTo(row, id, groupId) {
      let to = row.path
      this.$router.push(to)
      let url = '/' + to.split('/')[1];
      let arr = appsData.filter((item) => {
        return item.activeRule === url;
      });
      dynamicRegisterApps(arr);
    }
    ,
  },
}
</script>
<style lang="less">
#subapp-viewport {
  height: 100%;
  width: 100%;

  & > div {
    height: 100%;
    width: 100%;
  }
}
</style>
<style lang="less" scoped>
@import "../../assets/css/common.less";

::v-deep(.searchIcon) {
  .el-icon {
    color: var(--el-menu-search-icon-color) !important;
  }
}

.page-main {
  height: 100vh;
  width: 100vw;
  background: #FAFAFB;

  .menu-line {
    border-bottom: 1px solid var(--el-menu-line-color)
  }

  .layout {
    ::v-deep(.collapseBtn) {
      color: var(--el-menu-search-arrow-icon-color);
    }

    ::v-deep(.collapseBtn:hover) {
      background: var(--el-menu-search-arrow-hover-bg-color) !important;
      color: var(--el-menu-search-arrow-icon-hover-color) !important;
      border-color: transparent !important;

      .el-icon {
        color: var(--el-menu-search-arrow-icon-hover-color) !important;
      }
    }

    ::v-deep(.collapseBtn.el-button, .collapseBtn.el-button ) {
      color: var(--el-menu-search-arrow-icon-color);
      border-color: transparent !important;
      background-color: var(--el-menu-search-arrow-bg-color);
      outline: 0;
      margin-left: 0.375rem;
    }

    .searchIcon {
      ::v-deep(.el-input__prefix) {
        color: var(--el-menu-search-arrow-icon-color);
      }

      ::v-deep(input::-webkit-input-placeholder) {
        -webkit-text-fill-color: var(--el-menu-search-place-color);
      }

      ::v-deep(.el-input__wrapper) {
        .el-input__inner {
          color: var(--el-menu-search-active-text-color) !important;
          border: none;
          background: none !important;
          height: 32px;
        }

        background-color: var(--el-menu-search-active-bg-color);
      }

      ::v-deep(.el-input__wrapper.is-focus) {
        .el-input__inner {
          color: var(--el-menu-search-active-border-color) !important;
        }

        background-color: var(--el-menu-search-active-bg-color);
        border: 1px solid var(--el-menu-search-active-border-color);
      }

      ::v-deep(.el-input__wrapper) {
        background-color: var(--el-menu-search-bg-color);
        box-shadow: 0 0 0 1px var(--el-menu-search-border-color, var(--el-menu-search-border-color)) inset;
      }
    }
  }

  .danwei {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: left;
    font-style: normal;
    text-transform: none;

    .line {
      width: 1px;
      height: 30px;
      background: @border-color;
    }
  }

  .shortcut {
    display: inline-block;
    height: 20px;
    margin-right: 20px;
  }

  .p_box {

    .p1 {
      color: @text-color;
      font-size: 14px;
    }

    .p2 {
      color: @text-color;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei;
    }

    .p3 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
    }

    .p4 {
      font-family: Microsoft YaHei, Microsoft YaHei, serif;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }


  }

  .tips {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #FF0000;
    border-radius: 50%;
  }
}

.bg-white {
  background: #fff;
}

.layout-main {
  background: #FAFAFB;
  padding: 0 20px;
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.menu-shadow {
  //box-shadow: 0px 0px 6px 1px rgba(3, 75, 143, 0.1608);
  border-right: 1px solid var(--menu-right)
}
</style>

<style>
.el-menu-item.is-active {
  color: var(--el-menu-active-color) !important;
}

.classTestwyc {
  display: inline-block;
  padding: 10px 20px;
  width: 88px;
  height: 76px;
}

.classTestwyc .el-dropdown__list {
  height: calc(100%);
}

.classTestwyc .el-dropdown-menu {
  height: 100%;
  width: 100%;
  margin: 0;
}

.classTestwyc .el-dropdown-menu .el-dropdown-menu__item {
  /* height: 100%; */
  width: 100%;
  line-height: 22px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin: 0px;
  display: block;
  padding-bottom: 3px;
  padding-top: 3px;
}

.span-primary {
  color: var(--el-color-primary);
}

.span-text {
  color: #8C8C8C;
}


</style>
