import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {ElLoading, ElNotification, ElMessage} from 'element-plus'
import store from "../store";
import actions from '../action';

const customConfig = {
    '/api/app/getPreSignedUrl': false
};
let loading
// 创建 axios 实例
const request = axios.create({
    baseURL: '/fire',
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    // API 请求的默认前缀
    timeout: 60000, // 请求超时时间,
})

// 异常拦截处理器
const errorHandler = (error) => {
    loading && loading.close()
    if (error.response.status == 401) {
        // 退出登录
        actions.setGlobalState({noToken: true, activePath: '/'});
    }
    ElMessage({type: 'error', message: error.response.data.msg, grouping: true, repeatNum: 1})
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    const isLoading = customConfig[config.url] || false;
    // 如果 isLoading 为 true，则显示加载动画
    if (isLoading) {
        loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.4)'
        })
    }
    const token = sessionStorage.getItem("token");
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Authorization'] = JSON.parse(token)
    }else{
        config.headers['Authorization'] =''
    }
    config.headers['Mark'] = '122'
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    const {data} = response
    // const { getStatus, logout } = useLayoutStore()
    loading && loading.close()
    // 二进制数据则直接返回
    if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
        return response
    }
    if (data.code !== 200) {
        let title = '请求失败'
        if (data.code === 401) {
            // 退出登录
            actions.setGlobalState({noToken: true, activePath: '/'});
        }
        ElMessage({type: 'error', message: data.msg, grouping: true, repeatNum: 1})
        return Promise.reject(data.response)
    }
    return response.data
}, errorHandler)


export default request
